import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import { Module, ModuleInterface } from '../module/module';
export interface PointOfInterestInterface extends BaseModelInterface {
	name: string;
	label: string;
	details: string;
	picture?: string | Image | ImageInterface;
	latitude: number;
	longitude: number;
	modules?: (string | Module | ModuleInterface)[];
	created_at: number | Date;
}
export interface PointOfInterestPayload {
	name: string;
	label: string;
	details: string;
	picture?: string | Image | ImageInterface;
	latitude: number;
	longitude: number;
	modules?: (string | Module | ModuleInterface)[];
}
type PointOfInterestPayloadKey = keyof PointOfInterestPayload;
export class PointOfInterest extends BaseModel<
	PointOfInterestInterface,
	PointOfInterestPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Denotes if the instance of picture has been populated */
	pictureExists(): boolean {
		return (
			!!this.props &&
			this.props.picture instanceof Image &&
			this.props.picture.exists()
		);
	}
	/** Denotes if the instance of modules has been populated */
	modulesExists(): boolean {
		return (
			!!this.props &&
			this.props.modules instanceof Array &&
			(<Module[]>this.props.modules).every((item) => {
				return item instanceof Module && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: PointOfInterestInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (
			this.props.picture !== null &&
			typeof this.props.picture === 'object'
		) {
			this.props.picture = new Image(<ImageInterface>this.props.picture);
		}
		if (this.props.modules instanceof Array) {
			this.props.modules = (<ModuleInterface[]>this.props.modules).map(
				(item) => {
					return typeof item === 'object' ? new Module(item) : item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): PointOfInterestInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.picture === 'undefined') {
			props.picture = null;
		}
		if (typeof props.modules === 'undefined') {
			props.modules = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.picture !== null && props.picture instanceof Image) {
			props.picture = props.picture.toObject();
		}
		if (this.props.modules instanceof Array) {
			props.modules = (<Module[]>props.modules).map((item) => {
				return item instanceof Module ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): PointOfInterestPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as PointOfInterestInterface);
		payload.picture = payload.picture
			? this.extractId(payload.picture)
			: null;
		payload.modules = payload.modules
			? payload.modules.map((i) => this.extractId(i))
			: null;
		return payload as PointOfInterestPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): PointOfInterestPayloadKey[] {
		return [
			'name',
			'label',
			'details',
			'picture',
			'latitude',
			'longitude',
			'modules',
		];
	}
}
