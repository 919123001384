import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { MediaAudio } from '../media-audio';
import { MediaAudioService } from '../media-audio.service';
@Component({
	selector: 'hpf-media-audio-form',
	templateUrl: './media-audio-form.component.html',
})
export class MediaAudioFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The media audio to inject in the form */
	@Input() mediaAudio: MediaAudio;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<MediaAudio>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private mediaAudioService: MediaAudioService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'media_audio',
			'update'
		));

		// Init model
		if (!this.mediaAudio) this.mediaAudio = new MediaAudio();

		// Create form
		this.form = this.formBuilder.group({
			title: new FormControl(this.mediaAudio.props.title, [
				Validators.required,
			]),
			thumbnail: new FormControl(this.mediaAudio.props.thumbnail, []),
			audio: new FormControl(this.mediaAudio.props.audio, [
				Validators.required,
			]),
			label: new FormControl(this.mediaAudio.props.label, [
				Validators.required,
			]),
		});

		// Update form when model loads or changes
		this.modelSubscription = this.mediaAudio.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();

			// Creation or update ?
			if (this.mediaAudio.isNew()) {
				// Creation
				const mediaAudio: MediaAudio = await this.mediaAudioService.create(
					this.mediaAudio.toPayload()
				);
				this.create.next(mediaAudio);
			} else {
				// Update
				await this.mediaAudioService.update(
					this.mediaAudio.getId(),
					this.mediaAudio.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.mediaAudio.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			title: this.mediaAudio.props.title,
			thumbnail: this.mediaAudio.props.thumbnail,
			audio: this.mediaAudio.props.audio,
			label: this.mediaAudio.props.label,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.mediaAudioService
			.remove(this.mediaAudio.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
