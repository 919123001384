<nz-modal [(nzVisible)]="deleteModal" (nzOnCancel)="deleteModal = false" (nzOnOk)="onDelete()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<form nz-form class="media-image-form w-100" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()"
  [nzAutoTips]="explainErrorsService.autoTips">
  <nz-affix>
    <div class="py-4 border-bottom bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <!-- Title -->
            <div>
              <h1 class="mb-0">
                <span *ngIf="mediaImage.isNew()">{{ 'media-image_create-form-title' | translate }}</span>
                <span *ngIf="!mediaImage.isNew()">{{ mediaImage.getLabel() }}</span>
              </h1>
              <small *ngIf="!mediaImage.isNew()"
                class="text-black-50">{{ 'common_id' | translate: { id : mediaImage.getId() } }}</small>
            </div>
            <!-- /Title -->

            <!-- Actions -->
            <div>
              <button nz-button [disabled]="form.invalid" [nzLoading]="saving" nzType="primary" *appRestrictedByScope="{
                                    model: 'media_image',
                                    action: 'update'
                                }">
                {{ 'common_save' | translate }}
              </button>
              <ng-container *appRestrictedByScope="{
                                model: 'media_image',
                                action: 'delete'
                            }">
                <button *ngIf="!mediaImage.isNew() && deletable" class="ml-4" nz-button nzShape="circle"
                  [title]="'common_delete' | translate" [nzLoading]="deleting" (click)="deleteModal = true"
                  type="button">
                  <i nz-icon nzType="delete"></i>
                </button>
              </ng-container>
            </div>
            <!-- /Actions -->
          </div>
        </div>
      </div>
    </div>
  </nz-affix>
  <div class="container">
    <nz-card class="round-border my-5">
      <div *ngIf="loading" class="row">
        <div class="col-12">
          <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
      </div>
      <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
          <div class="col-12  col-md-6">
            <!-- label -->


            <nz-form-item>
              <h4>{{ 'label' | translate }}</h4>
              <nz-form-control>


                <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
                  formControlName="label">
              </nz-form-control>
            </nz-form-item>
            <!-- /label -->
          </div>
          <div class="col-12  col-md-6">
            <!-- Title -->


            <nz-form-item>
              <h4>{{ 'media-image_title' | translate }}</h4>
              <nz-form-control>


                <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
                  formControlName="title">
              </nz-form-control>
            </nz-form-item>
            <!-- /Title -->
          </div>
          <div class="col-12  col-md-6">
            <!-- Thumbnail -->


            <nz-form-item>
              <h4>{{ 'media-image_thumbnail' | translate }}</h4>
              <nz-form-control [nzValidateStatus]="form.get('thumbnail')">



                <app-image-modal-button formControlName="thumbnail"></app-image-modal-button>
              </nz-form-control>
            </nz-form-item>
            <!-- /Thumbnail -->
          </div>
          <div class="col-12  col-md-6">
            <!-- Image -->


            <nz-form-item>
              <h4>{{ 'media-image_image' | translate }}</h4>
              <nz-form-control [nzValidateStatus]="form.get('image')">



                <app-image-modal-button formControlName="image"></app-image-modal-button>
              </nz-form-control>
            </nz-form-item>
            <!-- /Image -->
          </div>
        </div>

        <nz-divider *ngIf="!mediaImage.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!mediaImage.isNew()" class="row">
          <div class="col-12 col-md-6">
            <!-- _Id -->
            <div class="mb-4">
              <h4>{{ 'media-image_-id' | translate }}</h4>
              <div>{{ mediaImage.props._id }}</div>
            </div>
            <!-- /_Id -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Title -->
            <div class="mb-4">
              <h4>{{ 'media-image_title' | translate }}</h4>
              <div>{{ mediaImage.props.title }}</div>
            </div>
            <!-- /Title -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Thumbnail -->
            <div class="mb-4">
              <h4>{{ 'media-image_thumbnail' | translate }}</h4>
              <div *ngIf="mediaImage.thumbnailExists()">
                <a [routerLink]="['/image', mediaImage.props.thumbnail.getId()]">
                  {{ mediaImage.props.thumbnail.getLabel() }}
                </a>
              </div>
            </div>
            <!-- /Thumbnail -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Image -->
            <div class="mb-4">
              <h4>{{ 'media-image_image' | translate }}</h4>
              <div *ngIf="mediaImage.imageExists()">
                <a [routerLink]="['/image', mediaImage.props.image.getId()]">
                  {{ mediaImage.props.image.getLabel() }}
                </a>
              </div>
            </div>
            <!-- /Image -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Created At -->
            <div class="mb-4">
              <h4>{{ 'media-image_created-at' | translate }}</h4>
              <div>{{ mediaImage.props.created_at | date:'medium' }}</div>
            </div>
            <!-- /Created At -->
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</form>
