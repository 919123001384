import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import { Audio, AudioInterface } from '../audio/audio';
export interface MediaAudioInterface extends BaseModelInterface {
	title: string;
	label: string;
	thumbnail?: string | Image | ImageInterface;
	audio: string | Audio | AudioInterface;
	created_at: number | Date;
}
export interface MediaAudioPayload {
	title: string;
	label: string;
	thumbnail?: string | Image | ImageInterface;
	audio: string | Audio | AudioInterface;
}
type MediaAudioPayloadKey = keyof MediaAudioPayload;
export class MediaAudio extends BaseModel<
	MediaAudioInterface,
	MediaAudioPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Denotes if the instance of thumbnail has been populated */
	thumbnailExists(): boolean {
		return (
			!!this.props &&
			this.props.thumbnail instanceof Image &&
			this.props.thumbnail.exists()
		);
	}
	/** Denotes if the instance of audio has been populated */
	audioExists(): boolean {
		return (
			!!this.props &&
			this.props.audio instanceof Audio &&
			this.props.audio.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: MediaAudioInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (
			this.props.thumbnail !== null &&
			typeof this.props.thumbnail === 'object'
		) {
			this.props.thumbnail = new Image(
				<ImageInterface>this.props.thumbnail
			);
		}
		if (typeof this.props.audio === 'object') {
			this.props.audio = new Audio(<AudioInterface>this.props.audio);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): MediaAudioInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.thumbnail === 'undefined') {
			props.thumbnail = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.thumbnail !== null && props.thumbnail instanceof Image) {
			props.thumbnail = props.thumbnail.toObject();
		}
		if (props.audio instanceof Audio) {
			props.audio = props.audio.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): MediaAudioPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as MediaAudioInterface);
		payload.thumbnail = payload.thumbnail
			? this.extractId(payload.thumbnail)
			: null;
		payload.audio = payload.audio ? this.extractId(payload.audio) : null;
		return payload as MediaAudioPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): MediaAudioPayloadKey[] {
		return ['title', 'thumbnail', 'audio', 'label'];
	}
}
