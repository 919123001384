import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import { Image360, Image360Interface } from '../image-360/image-360';
export interface Media360ImageInterface extends BaseModelInterface {
	title: string;
	label: string;
	thumbnail?: string | Image | ImageInterface;
	image: string | Image360 | Image360Interface;
	created_at: number | Date;
}
export interface Media360ImagePayload {
	title: string;
	label: string;
	thumbnail?: string | Image | ImageInterface;
	image: string | Image360 | Image360Interface;
}
type Media360ImagePayloadKey = keyof Media360ImagePayload;
export class Media360Image extends BaseModel<
	Media360ImageInterface,
	Media360ImagePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Denotes if the instance of thumbnail has been populated */
	thumbnailExists(): boolean {
		return (
			!!this.props &&
			this.props.thumbnail instanceof Image &&
			this.props.thumbnail.exists()
		);
	}
	/** Denotes if the instance of image has been populated */
	imageExists(): boolean {
		return (
			!!this.props &&
			this.props.image instanceof Image360 &&
			this.props.image.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: Media360ImageInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (
			this.props.thumbnail !== null &&
			typeof this.props.thumbnail === 'object'
		) {
			this.props.thumbnail = new Image(
				<ImageInterface>this.props.thumbnail
			);
		}
		if (typeof this.props.image === 'object') {
			this.props.image = new Image360(
				<Image360Interface>this.props.image
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): Media360ImageInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.thumbnail === 'undefined') {
			props.thumbnail = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.thumbnail !== null && props.thumbnail instanceof Image) {
			props.thumbnail = props.thumbnail.toObject();
		}
		if (props.image instanceof Image360) {
			props.image = props.image.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): Media360ImagePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as Media360ImageInterface);
		payload.thumbnail = payload.thumbnail
			? this.extractId(payload.thumbnail)
			: null;
		payload.image = payload.image ? this.extractId(payload.image) : null;
		return payload as Media360ImagePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): Media360ImagePayloadKey[] {
		return ['title', 'thumbnail', 'image', 'label'];
	}
}
