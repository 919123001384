import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { InstructionFormArrayComponent } from '../../instruction/instruction-form-array/instruction-form-array.component';
import { MultiChoiceCategoryFormArrayComponent } from '../../multi-choice-category/multi-choice-category-form-array/multi-choice-category-form-array.component';
import { Helpers } from '@app/shared';
import { MultiChoiceQuizModule } from '../multi-choice-quiz-module';
import { MultiChoiceQuizModuleService } from '../multi-choice-quiz-module.service';
@Component({
	selector: 'hpf-multi-choice-quiz-module-form',
	templateUrl: './multi-choice-quiz-module-form.component.html',
})
export class MultiChoiceQuizModuleFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The multi choice quiz module to inject in the form */
	@Input() multiChoiceQuizModule: MultiChoiceQuizModule;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<MultiChoiceQuizModule>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	@ViewChildren(InstructionFormArrayComponent) instructionsforms: QueryList<
		InstructionFormArrayComponent
	>;
	@ViewChildren(MultiChoiceCategoryFormArrayComponent)
	categoriesforms: QueryList<MultiChoiceCategoryFormArrayComponent>;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private multiChoiceQuizModuleService: MultiChoiceQuizModuleService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'multi_choice_quiz_module',
			'update'
		));

		// Init model
		if (!this.multiChoiceQuizModule)
			this.multiChoiceQuizModule = new MultiChoiceQuizModule();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.multiChoiceQuizModule.props.name, [
				Validators.required,
			]),
			label: new FormControl(this.multiChoiceQuizModule.props.name, [
				Validators.required,
			]),
			memory_aid: new FormControl(
				this.multiChoiceQuizModule.props.memory_aid,
				[]
			),
			answers: new FormControl(
				this.multiChoiceQuizModule.props.answers,
				[]
			),
			instructions: new FormControl(
				this.multiChoiceQuizModule.props.instructions,
				[]
			),
			categories: new FormControl(
				this.multiChoiceQuizModule.props.categories,
				[]
			),
		});

		// Update form when model loads or changes
		this.modelSubscription = this.multiChoiceQuizModule.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			for (let form of this.instructionsforms) {
				await form.submit();
			}
			for (let form of this.categoriesforms) {
				await form.submit();
			}
			// Update model
			this.updateModel();

			// Creation or update ?
			if (this.multiChoiceQuizModule.isNew()) {
				// Creation
				const multiChoiceQuizModule: MultiChoiceQuizModule = await this.multiChoiceQuizModuleService.create(
					this.multiChoiceQuizModule.toPayload()
				);
				this.create.next(multiChoiceQuizModule);
			} else {
				// Update
				await this.multiChoiceQuizModuleService.update(
					this.multiChoiceQuizModule.getId(),
					this.multiChoiceQuizModule.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.multiChoiceQuizModule.props[key] = this.form.get(key).value;
		}
		if (typeof this.multiChoiceQuizModule.props.answers === 'string') {
			this.multiChoiceQuizModule.props.answers = this
				.multiChoiceQuizModule.props.answers
				? JSON.parse(this.multiChoiceQuizModule.props.answers)
				: null;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.multiChoiceQuizModule.props.name,
			label: this.multiChoiceQuizModule.props.label,
			memory_aid: this.multiChoiceQuizModule.props.memory_aid,
			answers: this.multiChoiceQuizModule.props.answers,
			instructions: this.multiChoiceQuizModule.props.instructions,
			categories: this.multiChoiceQuizModule.props.categories,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.multiChoiceQuizModuleService
			.remove(this.multiChoiceQuizModule.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
