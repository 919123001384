import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { OralCategoryFormArrayComponent } from '../../oral-category/oral-category-form-array/oral-category-form-array.component';
import { InstructionFormArrayComponent } from '../../instruction/instruction-form-array/instruction-form-array.component';
import { Helpers } from '@app/shared';
import { OralQuizModule } from '../oral-quiz-module';
import { OralQuizModuleService } from '../oral-quiz-module.service';
@Component({
	selector: 'hpf-oral-quiz-module-form',
	templateUrl: './oral-quiz-module-form.component.html',
})
export class OralQuizModuleFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The oral quiz module to inject in the form */
	@Input() oralQuizModule: OralQuizModule;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<OralQuizModule>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	@ViewChildren(OralCategoryFormArrayComponent) categoriesforms: QueryList<
		OralCategoryFormArrayComponent
	>;
	@ViewChildren(InstructionFormArrayComponent) instructionsforms: QueryList<
		InstructionFormArrayComponent
	>;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private oralQuizModuleService: OralQuizModuleService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'oral_quiz_module',
			'update'
		));

		// Init model
		if (!this.oralQuizModule) this.oralQuizModule = new OralQuizModule();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.oralQuizModule.props.name, [
				Validators.required,
			]),
			label: new FormControl(this.oralQuizModule.props.label, [
				Validators.required,
			]),
			memory_aid: new FormControl(
				this.oralQuizModule.props.memory_aid,
				[]
			),
			background_image: new FormControl(
				this.oralQuizModule.props.background_image,
				[Validators.required]
			),
			question: new FormControl(this.oralQuizModule.props.question, [
				Validators.required,
			]),
			good_answers_per_category: new FormControl(
				this.oralQuizModule.props.good_answers_per_category,
				[Validators.required]
			),
			final_text: new FormControl(
				this.oralQuizModule.props.final_text,
				[]
			),
			final_image: new FormControl(this.oralQuizModule.props.final_image),
			timer_final_results: new FormControl(
				this.oralQuizModule.props.timer_final_results,
				[]
			),
			time_in_seconds: new FormControl(
				this.oralQuizModule.props.time_in_seconds,
				[Validators.required]
			),
			time_results_tiers: new FormControl(
				this.oralQuizModule.props.time_results_tiers,
				[]
			),
			categories: new FormControl(
				this.oralQuizModule.props.categories,
				[]
			),
			instructions: new FormControl(
				this.oralQuizModule.props.instructions,
				[]
			),
		});

		// Update form when model loads or changes
		this.modelSubscription = this.oralQuizModule.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			for (let form of this.categoriesforms) {
				await form.submit();
			}
			for (let form of this.instructionsforms) {
				await form.submit();
			}
			// Update model
			this.updateModel();

			// Creation or update ?
			if (this.oralQuizModule.isNew()) {
				// Creation
				const oralQuizModule: OralQuizModule = await this.oralQuizModuleService.create(
					this.oralQuizModule.toPayload()
				);
				this.create.next(oralQuizModule);
			} else {
				// Update
				await this.oralQuizModuleService.update(
					this.oralQuizModule.getId(),
					this.oralQuizModule.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.oralQuizModule.props[key] = this.form.get(key).value;
		}
		if (typeof this.oralQuizModule.props.timer_final_results === 'string') {
			this.oralQuizModule.props.timer_final_results = this.oralQuizModule
				.props.timer_final_results
				? JSON.parse(this.oralQuizModule.props.timer_final_results)
				: null;
		}
		if (typeof this.oralQuizModule.props.time_results_tiers === 'string') {
			this.oralQuizModule.props.time_results_tiers = this.oralQuizModule
				.props.time_results_tiers
				? JSON.parse(this.oralQuizModule.props.time_results_tiers)
				: null;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.oralQuizModule.props.name,
			memory_aid: this.oralQuizModule.props.memory_aid,
			background_image: this.oralQuizModule.props.background_image,
			question: this.oralQuizModule.props.question,
			good_answers_per_category: this.oralQuizModule.props
				.good_answers_per_category,
			final_text: this.oralQuizModule.props.final_text,
			final_image: this.oralQuizModule.props.final_image,
			timer_final_results: this.oralQuizModule.props.timer_final_results,

			time_in_seconds: this.oralQuizModule.props.time_in_seconds,
			time_results_tiers: this.oralQuizModule.props.time_results_tiers,
			categories: this.oralQuizModule.props.categories,
			instructions: this.oralQuizModule.props.instructions,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.oralQuizModuleService
			.remove(this.oralQuizModule.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
