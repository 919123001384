<nz-modal [(nzVisible)]="deleteOneModal" (nzOnCancel)="deleteOneModal = false" (nzOnOk)="removeOne()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<nz-modal [(nzVisible)]="deleteManyModal" (nzOnCancel)="deleteManyModal = false" (nzOnOk)="removeMany()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-many' | translate" [nzOkLoading]="deleting">
</nz-modal>
<div class="oral-quiz-module-list-container">
  <nz-table #table [nzData]="items" [nzSize]="'small'" [nzShowSizeChanger]="true" [nzFrontPagination]="false"
    [nzLoading]="refreshing" [nzLoadingDelay]="refreshingDelay" [nzTotal]="total" [nzNoResult]="notFound"
    [(nzPageIndex)]="pageIndex" [(nzPageSize)]="searchParams.props._limit" (nzPageIndexChange)="update()"
    (nzPageSizeChange)="update(true)">
    <thead (nzSortOrderChange)="sort($event)">
      <tr>
        <th *ngIf="enableMultiSelect">
          <button (click)="deleteManyModal = true" nz-button [disabled]="selectedRows.length === 0" nzType="default"
            nzShape="circle">
            <i nz-icon nzType="delete"></i>
          </button>
        </th>
        <th *ngIf="columns.includes('label')" nzColumnKey="label" [nzSortOrder]="getSortOrder('label')">
          {{ 'label' | translate }}</th>
        <th *ngIf="columns.includes('name')" nzColumnKey="name" [nzSortOrder]="getSortOrder('name')">
          {{ 'oral-quiz-module_name' | translate }}</th>
        <th *ngIf="columns.includes('memory_aid')">{{ 'oral-quiz-module_memory-aid' | translate }}</th>
        <th *ngIf="columns.includes('question')">{{ 'oral-quiz-module_question' | translate }}</th>
        <th *ngIf="columns.includes('good_answers_per_category')">
          {{ 'oral-quiz-module_good-answers-per-category' | translate }}</th>
        <th *ngIf="columns.includes('final_text')">{{ 'oral-quiz-module_final-text' | translate }}</th>
        <th *ngIf="columns.includes('timer_final_results')">{{ 'oral-quiz-module_timer-final-results' | translate }}
        </th>
        <th *ngIf="columns.includes('time_in_seconds')">{{ 'oral-quiz-module_time-in-seconds' | translate }}</th>
        <th *ngIf="columns.includes('time_results_tiers')">{{ 'oral-quiz-module_time-results-tiers' | translate }}</th>
        <th *ngIf="columns.includes('categories')">{{ 'oral-quiz-module_categories' | translate }}</th>
        <th *ngIf="columns.includes('instructions')">{{ 'oral-quiz-module_instructions' | translate }}</th>
        <th *ngIf="columns.includes('created_at')" nzColumnKey="created_at" [nzSortOrder]="getSortOrder('created_at')">
          {{ 'oral-quiz-module_created-at' | translate }}</th>
        <th *ngIf="showActions" class="actions-cell">
          {{ 'common_actions' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of table.data" (click)="onClick(row)">
        <td *ngIf="enableMultiSelect" nzShowCheckbox (nzCheckedChange)="selectRow($event, row)"
          [nzChecked]="isSelected(row)">
        </td>
        <td *ngIf="columns.includes('label')">
          {{ row.props.name }}
        </td>
        <td *ngIf="columns.includes('name')">
          {{ row.props.name }}
        </td>
        <td *ngIf="columns.includes('memory_aid')">
          <div [innerHTML]="row.props.memory_aid | nl2br"></div>
        </td>
        <td *ngIf="columns.includes('question')">
          {{ row.props.question }}
        </td>
        <td *ngIf="columns.includes('good_answers_per_category')">
          {{ row.props.good_answers_per_category }}
        </td>
        <td *ngIf="columns.includes('final_text')">
          {{ row.props.final_text }}
        </td>
        <td *ngIf="columns.includes('timer_final_results')">
          <small>
            <pre>{{ row.props.timer_final_results | json }}</pre>
          </small>
        </td>
        <td *ngIf="columns.includes('time_in_seconds')">
          {{ row.props.time_in_seconds }}
        </td>
        <td *ngIf="columns.includes('time_results_tiers')">
          <small>
            <pre>{{ row.props.time_results_tiers | json }}</pre>
          </small>
        </td>
        <td *ngIf="columns.includes('categories')">
          <div>
            <div *ngFor="let item of row.props.categories">
              {{ item.getLabel() }}
            </div>
          </div>
        </td>
        <td *ngIf="columns.includes('instructions')">
          <div>
            <div *ngFor="let item of row.props.instructions">
              {{ item.getLabel() }}
            </div>
          </div>
        </td>
        <td *ngIf="columns.includes('created_at')">
          {{ row.props.created_at | date:'medium' }}
        </td>
        <td *ngIf="showActions" class="actions-cell">
          <button (click)="rowToDelete = row; deleteOneModal = true; $event.stopPropagation()" nz-button
            nzType="default" nzShape="circle" [title]="'playlist-table_delete-button' | translate" class="ml-3">
            <i nz-icon nzType="delete"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ng-template #notFound>
    <nz-empty [nzNotFoundContent]="'oral-quiz-module_common_not-found' | translate "></nz-empty>
  </ng-template>
</div>
