import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import { Video, VideoInterface } from '../video/video';
export interface Media360VideoInterface extends BaseModelInterface {
	title: string;
	label: string;
	thumbnail?: string | Image | ImageInterface;
	video: string | Video | VideoInterface;
	subtitles?: string;
	created_at: number | Date;
}
export interface Media360VideoPayload {
	title: string;
	label: string;
	thumbnail?: string | Image | ImageInterface;
	video: string | Video | VideoInterface;
	subtitles?: string;
}
type Media360VideoPayloadKey = keyof Media360VideoPayload;
export class Media360Video extends BaseModel<
	Media360VideoInterface,
	Media360VideoPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Denotes if the instance of thumbnail has been populated */
	thumbnailExists(): boolean {
		return (
			!!this.props &&
			this.props.thumbnail instanceof Image &&
			this.props.thumbnail.exists()
		);
	}
	/** Denotes if the instance of video has been populated */
	videoExists(): boolean {
		return (
			!!this.props &&
			this.props.video instanceof Video &&
			this.props.video.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: Media360VideoInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (
			this.props.thumbnail !== null &&
			typeof this.props.thumbnail === 'object'
		) {
			this.props.thumbnail = new Image(
				<ImageInterface>this.props.thumbnail
			);
		}
		if (typeof this.props.video === 'object') {
			this.props.video = new Video(<VideoInterface>this.props.video);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): Media360VideoInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.thumbnail === 'undefined') {
			props.thumbnail = null;
		}
		if (
			typeof props.subtitles !== 'string' ||
			props.subtitles.length === 0
		) {
			props.subtitles = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.thumbnail !== null && props.thumbnail instanceof Image) {
			props.thumbnail = props.thumbnail.toObject();
		}
		if (props.video instanceof Video) {
			props.video = props.video.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): Media360VideoPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as Media360VideoInterface);
		payload.thumbnail = payload.thumbnail
			? this.extractId(payload.thumbnail)
			: null;
		payload.video = payload.video ? this.extractId(payload.video) : null;
		return payload as Media360VideoPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): Media360VideoPayloadKey[] {
		return ['title', 'thumbnail', 'video', 'subtitles', 'label'];
	}
}
